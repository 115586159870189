import React, { useEffect, useState } from "react";
import "./App.css";
import { HashRouter as Router, Routes, Route, Link } from "react-router-dom";
import Login from "./Login/Login";
import { colRef } from "./firebase-config";
import { onSnapshot } from "firebase/firestore";
import Home from "./Home/Home";
import Cms from "./Cms/Cms";

function App() {
  const [isAuth, setIsAuth] = useState(false);
 
  const [posts, setPosts] = useState([]);
  const [userN, setUserN] = useState('');
  const [userAuth, setUserAuth] = useState(null);

  useEffect(() => {
    let articols = [];
    onSnapshot(colRef, (snapshot) => {
      snapshot.docs.forEach((doc) => {
        articols.push({ ...doc.data(), id: doc.id });
      });
      function getUniqueListBy(articols, key) {
        return [...new Map(articols.map((item) => [item[key], item])).values()];
      }
      articols = getUniqueListBy(articols, "id");
      setPosts(articols);
    });
}, []);

  const isLogged = (isAuth, displayUserName) => {
    setUserAuth(isAuth);
    setUserN(displayUserName);
  }

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login setIsAuth={setIsAuth} isAuth={isAuth} />} />
        {isAuth && <Route path="/login/admin" element={<Cms readPosts={posts} isAuth={isAuth} setIsAuth={setIsAuth} setPosts={setPosts} />} />}
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
