import React, { useState, useEffect } from "react";
import "./Login.css";
import { auth } from "../firebase-config";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { Fragment } from "react/cjs/react.production.min";
import { useNavigate } from "react-router-dom";


/**
 * @author
 * @function Login
 **/

const Login = (props) => {
  let navigate = useNavigate();
  const [displayUserName, setDisplayUserName] = useState("Login");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const signIn = () => {
    signInWithEmailAndPassword(auth, userEmail, userPassword)
      .then((userCredential) => {
        const user = userCredential.user;
        const userName = user.email.split("@", [1]);
        props.setIsAuth(true);
        setDisplayUserName("Welcome " + userName);
        navigate("/login/admin");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode);
        console.log(errorMessage);
        setErrorMsg(error.message);
      });
    setUserEmail("");
    setUserPassword("");
    
  };

  let showP = "password";

  if (showPassword) {
    showP = "text";
  } else {
    showP = "password";
  }

  if (errorMsg !== "") {
    setTimeout(() => {
      setErrorMsg('');
    }, 2000);
  }

  return (
    <Fragment>
      <div className="login_container">
        Login
        <div className="input_group">
          <input
            onChange={(event) => {
              setUserEmail(event.target.value);
            }}
            id="login-email"
            type="email"
            name="email"
            placeholder="enter email"
            value={userEmail}
          />
          <div className="inpt">
            <input
              onChange={(event) => {
                setUserPassword(event.target.value);
              }}
              id="login-password"
              type={showP}
              name="password"
              placeholder="enter password"
              value={userPassword}
            />
            <div
              className="show_password"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              &#128065;
            </div>
          </div>
          <div
            className={`error_msg ${
              errorMsg !== "" ? "error_msg_display" : ""
            }`}
          >
            e-mail/ password invalid
          </div>
        </div>
        <button onClick={signIn}>Login</button>
        
      </div>
    </Fragment>
  );
};

export default Login;
