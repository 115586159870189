// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getFirestore, collection } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAM2xhs_bi16XCC2m24l26QWPioOLb4W2M",
  authDomain: "blogtest-14d66.firebaseapp.com",
  projectId: "blogtest-14d66",
  storageBucket: "blogtest-14d66.appspot.com",
  messagingSenderId: "755856008180",
  appId: "1:755856008180:web:ee320163153ac2a74f0fa1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

//get collection ref
export const colRef = collection(db, "Posts");

export const auth = getAuth(app);

export const storage = getStorage();

// Create the file metadata
/** @type {any} */
export const metadata = {
  contentType: 'image/jpeg'
};

