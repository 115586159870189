import React, { useState } from "react";
import "./Cms.css";
import Post from "../Post/Post";
import { colRef, auth, db } from "../firebase-config";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { deleteDoc, doc, onSnapshot, addDoc } from "firebase/firestore";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getStorage,
  deleteObject,
} from "firebase/storage";
/**
 * @author
 * @function Cms
 **/

const Cms = (props) => {
  let navigate = useNavigate();

  const signUserOut = () => {
    signOut(auth)
      .then(() => {
        props.setIsAuth(false);
        //setDisplayUserName("Login");
        navigate("/login");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode);
        console.log(errorMessage);
      });
  };

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [author, setAuthor] = useState("");
  const [date, setDate] = useState("");
  const [img, setImg] = useState("");

  const createPost = (event) => {
    event.preventDefault();
    handlerUploadImage();
  };

  const handlerUploadImage = () => {
    const storage = getStorage();
    // Create the file metadata
    /** @type {any} */
    const metadata = {
      contentType: "image/jpeg",
    };

    // Upload file and metadata to the object 'images/mountains.jpg'
    const storageRef = ref(storage, "images/" + img.name);
    const uploadTask = uploadBytesResumable(storageRef, img, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            console.log(error.code);
            break;
          case "storage/canceled":
            // User canceled the upload
            console.log(error.code);
            break;
          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            console.log(error.code);
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          //get url and then proceed with next operations
          addDoc(colRef, {
            title: title,
            content: content,
            author_name: author,
            author_id: auth.currentUser.uid,
            date: date,
            img: downloadURL,
            img_name: img.name,
          }).then(() => {
            setTitle("");
            setContent("");
            setDate("");
            setAuthor("");
            setImg("");
          });
        });
      }
    );
  };

  const deletePost = (idP, imgName) => {
    const docRef = doc(db, "Posts", idP);

    deleteImg(imgName);

    deleteDoc(docRef).then(() => {
      console.log("articol deleted");
      let articols = [];
      onSnapshot(colRef, (snapshot) => {
        snapshot.docs.forEach((doc) => {
          articols.push({ ...doc.data(), id: doc.id });
        });
        function getUniqueListBy(articols, key) {
          return [
            ...new Map(articols.map((item) => [item[key], item])).values(),
          ];
        }
        articols = getUniqueListBy(articols, "id");
        props.setPosts(articols);
      });
    });
  };

  const deleteImg = (imgName) => {
    const storage = getStorage();
    // Create a reference to the file to delete
    const imgRef = ref(storage, "images/" + imgName);
    // Delete the file
    deleteObject(imgRef)
      .then(() => {
        console.log("image deleted");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode);
        console.log(errorMessage);
      });
  };

  if (!props.isAuth) {
    navigate("/login");
  }

  return (
    <div className="cms_container">
      <button onClick={signUserOut}>SignOut</button>
      <div className="recent_posts">
        <div>Posts from server</div>
        <ul className="posts_list">
          <Post readPosts={props.readPosts} onDeletePost={deletePost} />
        </ul>
      </div>
      <div className="input_post">
        <div>Add New Post</div>
        <form className="cms_form" id="formPost">
          <input
            onChange={(event) => {
              setTitle(event.target.value);
            }}
            className="input"
            type="text"
            name="title"
            placeholder="title"
            value={title}
          />
          <textarea
            onChange={(event) => {
              setContent(event.target.value);
            }}
            className="textarea_input input"
            placeholder="cu inspiratie inainte..."
            name="content"
            value={content}
          ></textarea>
          <input
            onChange={(event) => {
              setAuthor(event.target.value);
            }}
            className="input"
            type="text"
            name="author"
            placeholder="author"
            value={author}
          />
          <input
            onChange={(event) => {
              setDate(event.target.value);
            }}
            className="input"
            type="date"
            name="date"
            value={date}
          />
          <input
            onChange={(event) => {
              setImg(event.target.files[0]);
            }}
            type="file"
            id="img"
            name="img"
            accept="image/*"
          />
        </form>
        <button
          onClick={createPost}
          className="form_btn"
          type="submit"
          form="formPost"
          value="Submit"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default Cms;
