import React from "react";
import "./Post.css";


/**
 * @author
 * @function Post
 **/

const Post = (props) => { 
  return (
    <div>
      {props.readPosts.map((post) => {
        return (
          <div className="cnt_post" key={post.id + "cntPost"}>
            <li key={post.id}>Title: {post.title}</li>
            <button key={post.id + "btnEdit"}>Edit</button>
            <button onClick={() => {props.onDeletePost(post.id, post.img_name)}}>Delete</button>
          </div>
        );
      })}
    </div>
  );
};
export default Post;
