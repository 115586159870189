import React from 'react';
import "./Home.css";


/**
* @author
* @function Home
**/

const Home = (props) => {
  return(
    <div>Home</div>
   )

 }

 export default Home;